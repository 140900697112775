import React from 'react';
import './ContactUs.scss';
import { connect } from 'react-redux';
import { useAccount } from '../../store/account';

export const ContactUs = () => {
  const accountStore = useAccount();

  return (
    <div className='primary-background'>
      <div className='container'>
        <div className='row justify-content-around p-4 p-md-5 communicate-with-us-content'>
          <div className='col-12 col-md-8'>
            <h4 className='font-weight-bold primary-color'>¿Tienes alguna pregunta?</h4>
            <p className='primary-color'>¡Estamos encantados de ayudarte! Por favor, si no encuentras respuesta a tu pregunta, no dudes en escribirnos y nos contactaremos contigo a la brevedad posible.</p>
          </div>
          <div className='col-12 col-md-4 text-right align-items-center d-flex'>
            <button className='code-access-background rounded-pill font-weight-bold p-3 border-0 communicate-with-us'>
              <a className='secondary-color' href={`tel://${accountStore.config?.contact_phone}`}>Comunícate al {accountStore.config?.contact_phone}</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { Component } from 'react';
import ProductImage from '../../components/product-details/ProductImage';
import ProductContent from '../../components/product-details/ProductContent';
import { getLot } from '../../services/LotsServices';
import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import './LotDetails.scss';
import ReactPlayer from 'react-player';

class LotDetails extends Component {
    state = {
        id: this.props.match.params.id,
        lot: {
            images: [],
            description: '',
            auction: '',
            date_close: '',
            price_base: '',
            id_lot: '',
            manualOffer: 0,
            id_remate: 0,
            timeLeftCancelOffer: 0,
            title: '',
        },
        loading: false,
        title: `Lote #${this.props.match.params.id}`,
        timestamp: null,
        piesocket: null,
        intervalAuction: null,
        intervalCountDown: null,
        nextLot: null,
        previousLot: null
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        this.fetchLot();
        //
        let interval = setInterval(() => {
            this.fetchLot(false);
        }, 15000);
        await this.setState({
            intervalAuction: interval,
        });
        //
        this.subscribeSocket(this.props.configAccount?.config?.socket_api_key);
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
            if (this.props.configAccount.config?.socket_api_key !== null) {
                this.subscribeSocket(this.props.configAccount?.config?.socket_api_key);
            }
        }
        // if change id of lot
        if (prevProps.match.params.id !== this.props.match.params.id) {
            await this.setState({
                id: this.props.match.params.id,
                lot: {
                    images: [],
                    description: '',
                    auction: '',
                    date_close: '',
                    price_base: '',
                    id_lot: '',
                    manualOffer: 0,
                    id_remate: 0,
                    timeLeftCancelOffer: 0,
                    title: '',
                },
                title: `Lote #${this.props.match.params.id}`,
                timestamp: null,
                piesocket: null,
                intervalAuction: null,
                intervalCountDown: null,
                nextLot: null,
                previousLot: null
            });
            this.fetchLot();
        }
    }

    fetchLot = async (loading) => {
        loading && await this.setState({ loading: true });
        try {
            let response = await getLot({
                id: this.state.id,
                token: this.props.sessionProps?.account?.token
            });
            if (this.state.lot.manualOffer > response.data.data.manualOffer) {
                response.data.data.manualOffer = this.state.lot.manualOffer;
            }
            let lot = response.data.data;
            lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0;
            await this.setState({
                lot: {
                    ...this.state.lot,
                    ...lot
                },
                timestamp: response.data.timestamp,
                nextLot: response.data.nextLot,
                previousLot: response.data.previousLot
            })
            this.getCountDown();
            if (lot.status !== 1 && lot.status !== 5 && this.state.intervalAuction !== null) {
                clearInterval(this.state.intervalAuction);
            }
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    getCountDown = async () => {
        if (this.state.intervalCountDown !== null) {
            clearInterval(this.state.intervalCountDown);
        }
        let interval = setInterval(async function () {
            await this.setState({
                timestamp: this.state.timestamp + 1
            })
        }.bind(this), 1000);
        await this.setState({
            intervalCountDown: interval
        });
    }

    subscribeSocket = async (apikey) => {
        if (this.state.piesocket !== null || !apikey) {
            return
        }
        let piesocket = new WebSocket(apikey);
        await this.setState({
            piesocket
        });
        console.log('Connected to the socket server');
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            if (data.socket_io_customer === window.socket_io_customer) {
                let lot = data.lot;
                if (lot && lot.id_remate === this.state.lot.id_remate && lot.id === this.state.lot.id) {
                    if (this.state.lot.manualOffer > lot.manualOffer) {
                        lot.manualOffer = this.state.lot.manualOffer;
                    }
                    lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0;
                    lot.automaticOffer = this.state.lot.automaticOffer;
                    await this.setState({
                        lot: {
                            ...this.state.lot,
                            ...lot
                        }
                    })
                }
            }
        }
    }

    componentWillUnmount = () => {
        this.state.intervalAuction !== null && clearInterval(this.state.intervalAuction);
        this.state.piesocket !== null && this.state.piesocket.close();
        console.log('closed')
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className='p-4 mt-5 product-box'>
                    <div className='container'>
                        <section className={'products-details-area row'}>
                            <div className='col-12 mb-2'>
                                <Link className='mb-2' to={`/subastas/${this.state.lot.id_remate}${this.props.match.params.y !== undefined ? '/' + this.props.match.params.y : ''}`}
                                    style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem' }}>
                                    <i className="fas fa-chevron-left text-primary-background"></i>
                                    <span className='text-primary-background font-weight-bold'> Volver al listado</span>
                                </Link>
                            </div>
                            <div className='col-12 mb-2'>
                                {
                                    this.state.previousLot && this.state.previousLot.id ? (
                                        <a className='mb-2' href={`/lotes/${this.state.previousLot.id}${this.props.match.params.y !== undefined ? '/' + this.props.match.params.y : ''}`}
                                            style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem' }}>
                                            <i className="fas fa-chevron-left text-primary-background"></i>
                                            <span className='text-primary-background font-weight-bold'> Anterior</span>
                                        </a>
                                    ) : null
                                }
                                {
                                    this.state.nextLot && this.state.nextLot.id ? (
                                        <a className='mb-2' href={`/lotes/${this.state.nextLot.id}${this.props.match.params.y !== undefined ? '/' + this.props.match.params.y : ''}`}
                                            style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem', float: 'right' }}>
                                            <span className='text-primary-background font-weight-bold'> Siguiente</span>
                                            <i className="fas fa-chevron-right text-primary-background"></i>
                                        </a>
                                    ) : null
                                }
                            </div>
                            <div className="col-12 col-md-5 order-md-1 order-2">
                                <ProductImage images={this.state.lot.images} id={this.state.lot.id} />
                                <span className='d-md-none'><ProductContent {...this.state.lot} {...this.props} id={this.state.id}
                                    timestamp={this.state.timestamp} fetchLot={() => this.fetchLot(false)} /></span>
                            </div>
                            <div className='col-12 col-md-5 order-md-2 order-1'>
                                <span className='d-none d-md-block'><ProductContent {...this.state.lot} {...this.props}
                                    id={this.state.id} timestamp={this.state.timestamp} fetchLot={() => this.fetchLot(false)} /></span>
                            </div>
                            {
                                this.state.lot?.youtube_link ? (
                                    <div className='col-12 order-3'>
                                        <div className="video-youtube" style={{ padding: 0, marginTop: '2rem' }}>
                                            <ReactPlayer controls={true} url={this.state.lot.youtube_link} width="100%" height="100%" />
                                        </div>
                                    </div>
                                ) : null
                            }
                        </section>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LotDetails);